<div class="card">
  @if (!headless()) {
    <div class="card-header flex-wrap" [class.p-0]="!filters.children.length">
      @if (header()) {
        <h5 class="w-100 mb-5">{{ header() }}</h5>
      }
      <div class="d-flex w-100 justify-content-between flex-wrap flex-lg-nowrap">
        <div class="w-100 grid-filter">
          <div (keyup.enter)="!this.hideSearchButton() && searchTrigger.emit()" class="row w-100" #filters>
            <ng-content select="[slot=filters]"></ng-content>
            @if (filters.children.length > 0 && !this.hideSearchButton()) {
              <div class="col-1 ui-float-label">
                <button class="btn btn-primary" type="button" [disabled]="loading()" (click)="searchTrigger.emit()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            }
          </div>
        </div>
        <div class="d-flex flex-nowrap grid-config-items ui-float-label ms-4">
          <ng-content select="[slot=configuration]"></ng-content>
          @if (exportEnabled()) {
            <button type="button" class="btn btn-success" pTooltip="Export CSV" tooltipPosition="top" (click)="export()">
              <i class="fa fa-file-o"></i>
            </button>
          }
          @if (name()) {
            <div class="d-flex align-items-center p-0 ms-3">
              <div class="ui-float-label w-100">
                @if (presets()) {
                  <p-dropdown
                    [options]="presets()"
                    [ngModel]="selectedPreset()"
                    (onChange)="selectPreset($event.value)"
                    [virtualScroll]="false"
                    [virtualScrollItemSize]="24.5"
                    [overlayOptions]="{ autoZIndex: true, baseZIndex: 1100 }"
                    id="{{ name() }}-grid-preset"
                    class="w-100"
                  ></p-dropdown>
                }
                <label class="active">Column preset:</label>
              </div>
            </div>
            <button type="button" class="btn btn-light" pTooltip="Configure preset" tooltipPosition="top" (click)="configureColumns()">
              <i class="fa fa-cog"></i>
            </button>
            <button type="button" class="btn btn-danger ms-3" pTooltip="Remove preset" (click)="removePreset()">
              <i class="fa fa-trash"></i>
            </button>
          }
        </div>
      </div>
      <ng-content select="[slot=extras]"></ng-content>
    </div>
  }
  @if (loading()) {
    <div class="grid-loader position-absolute w-100 h-100 pt-5 d-flex justify-content-center align-items-center">
      <div class="whirly-loader"></div>
    </div>
  }
  <div class="grid-wrapper card-block">
    <p-table
      [columns]="tableValue().length ? columns() : []"
      [value]="tableValue()"
      [scrollable]="scrollable()"
      [virtualScroll]="vScroll()"
      [virtualScrollItemSize]="rowHeight()"
      [rowTrackBy]="trackRowChange"
      [dataKey]="idField()!"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      [paginator]="pagination().enabled"
      [rowsPerPageOptions]="[25, 50, 100]"
      [rows]="pagination().pageSize"
      [lazy]="pagination().external"
      (onLazyLoad)="changePagination($event)"
      [first]="pagination().page * pagination().pageSize"
      [totalRecords]="pagination().totalRecords || tableValue().length"
      [styleClass]="styleClass()"
      [selectionMode]="selectionMode()"
      [sortField]="sortField()"
      [sortOrder]="sortOrder()"
      [sortMode]="sortMode()"
      [multiSortMeta]="multiSortMeta()"
      [(selection)]="selection"
      (onRowSelect)="rowSelection()"
      (onRowUnselect)="rowSelection()"
      (onHeaderCheckboxToggle)="rowSelection()"
    >
      <ng-template pTemplate="header" let-columns>
        <ng-content select="[slot=customHeader]"></ng-content>
        <tr class="table-dark">
          @if (enableCheckboxSelection()) {
            <th style="flex: 0 0 60px; min-width: 60px">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
          }
          @for (column of columns; track trackColumnChange($index, column)) {
            <th
              [pSortableColumn]="column.sortable && column.field"
              [pTooltip]="column.tooltip"
              tooltipPosition="top"
              [class]="column.headClass"
              [ngStyle]="{
                flex: column.width ? column.width + ' 0 0' : '1 0 0',
                minWidth: column.width || colWidth(),
                maxWidth: fixedColWidth() ? colWidth() : 'none'
              }"
              pFrozenColumn
              [frozen]="column.frozenColumn"
            >
              @if (getHeadCell(column.field)?.template; as template) {
                <ng-container
                  *ngTemplateOutlet="template; context: { column: column, totals: totals(), dataSize: tableValue().length }"
                ></ng-container>
              } @else {
                {{ column.label }}
              }
              @if (column.sortable) {
                <p-sortIcon [field]="column.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
        <tr
          [ngStyle]="vScroll() ? { height: rowHeight() + 'px' } : { minHeight: rowHeight() + 'px' }"
          [class]="rowClassFunc()(rowData)"
          [ngClass]="{ 'disable-outline': !selectionMode() }"
          [pSelectableRow]="rowData"
          [pSelectableRowIndex]="rowIndex"
        >
          @if (enableCheckboxSelection()) {
            <td style="flex: 0 0 60px; min-width: 60px">
              <p-tableCheckbox [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [value]="rowData"></p-tableCheckbox>
            </td>
          }
          @for (column of columns; track trackColumnChange($index, column); let firstColumn = $first) {
            <td
              [class]="getStyleClass(column.bodyClass, rowData)"
              [ngStyle]="{
                flex: column.width ? column.width + ' 0 0' : '1 0 0',
                minWidth: column.width || colWidth(),
                maxWidth: fixedColWidth() ? colWidth() : 'none'
              }"
              (click)="cellClick.emit({ row: rowData, rowIndex: rowIndex, column: column })"
              pFrozenColumn
              [frozen]="column.frozenColumn"
            >
              @if (getBodyCell(column.field)?.template; as template) {
                <ng-container
                  *ngTemplateOutlet="
                    template;
                    context: {
                      column: column,
                      row: rowData,
                      rowIndex: rowIndex,
                      value: formatValue(rowData, column),
                      changes: changes()[rowIndex]?.[column.field],
                      changeCount: changeCount()[rowIndex],
                      changeTooltip: changeCount()[rowIndex]
                    }
                  "
                ></ng-container>
              } @else {
                @if (column.routerLink) {
                  <a
                    [routerLink]="column.routerLink(rowData)"
                    [queryParams]="column.queryParams?.(rowData)"
                    [target]="column.linkTarget || '_self'"
                    >{{ formatValue(rowData, column) }}</a
                  >
                } @else if ($any(changes()[rowIndex])?.[column.field]) {
                  <span
                    [pTooltip]="$any(changes()[rowIndex])?.[column.field]?.tooltip!"
                    tooltipStyleClass="bg-warning"
                    tooltipPosition="top"
                    [class.text-warning]="$any(changes()[rowIndex])?.[column.field]"
                    >{{ formatValue(rowData, column) }}</span
                  >
                } @else {
                  <span>{{ formatValue(rowData, column) }}</span>
                }
                @if (firstColumn && changeCount()[rowIndex]) {
                  <span
                    class="badge rounded-pill bg-warning ms-2"
                    [pTooltip]="changeTooltip()[rowIndex]"
                    [escape]="false"
                    tooltipStyleClass="bg-warning"
                    tooltipPosition="top"
                    >{{ changeCount()[rowIndex] }}</span
                  >
                }
              }
              @if (column.rowToggler) {
                <a href="#" [pRowToggler]="rowData">
                  <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                </a>
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-columns>
        @if (!loading() && tableValue().length > 0 && showFooter()) {
          <tr>
            @if (enableCheckboxSelection()) {
              <td style="flex: 0 0 60px; min-width: 60px"></td>
            }
            @for (column of columns; track trackColumnChange($index, column)) {
              <td
                [ngStyle]="{
                  flex: column.width ? column.width + ' 0 0' : '1 0 0',
                  minWidth: column.width || colWidth(),
                  maxWidth: fixedColWidth() ? colWidth() : 'none'
                }"
              >
                @if (getFootCell(column.field)?.template; as template) {
                  <div class="grid-footer-cell">
                    <ng-container *ngTemplateOutlet="template; context: { column: column }"></ng-container>
                  </div>
                }
              </td>
            }
          </tr>
        }
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-row>
        @if (rowExpansion()) {
          <ng-container *ngTemplateOutlet="rowExpansion()!.template; context: { row: row }"></ng-container>
        }
      </ng-template>
    </p-table>
    @if (!loading() && tableValue().length === 0) {
      <div class="text-center pt-5 pb-5">
        <i class="fas fa-file-alt"></i>
        No records found
      </div>
    }
  </div>
</div>
